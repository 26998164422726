import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./style.module.scss";

const useStyles = makeStyles({
  label: props => ({
    fontWeight: 500,
    fontSize: "14px",
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    transform: "none",
    color: "#9398A0",
    "&.Mui-focused": {
      color: "#9398A0"
    },
    lineHeight: "16px",
    ...props.label
  }),
  rootInput: props => ({
    border: "1px solid #D7DCE5",
    borderRadius: "5px",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    color: "#4A525F",
    padding: 0,
    marginTop: props.showLabel ? "22px !important" : "0",
    lineHeight: "16px",
    minHeight: "none",
    ...props.rootInput
  }),
  rootInputFocused: props => ({
    borderColor: "#a2a2a2",
    ...props.rootInputFocused
  }),
  rootInputDisabled: props => ({
    padding: "1px",
    background: "#f7f8fa",
    border: "none",
    color: "#4A525F",
    ...props.rootInputDisabled
  }),
  rootInputError: props => ({
    border: "1px solid rgb(220, 53, 69)",
    ...props.rootInputError
  }),
  input: props => ({
    boxSizing: "content-box !important",
    height: "16px",
    padding: "11px 10px",
    borderRadius: "5px",
    "&::placeholder": {
      color: "#9398A0",
      opacity: 1
    },
    ...props.input
  })
});

const TextInput = React.forwardRef(
  (
    {
      id,
      label,
      name,
      value,
      placeholder,
      error,
      onChange,
      onBlur,
      disabled,
      required,
      type = "text",
      showLabel = true,
      loading,
      inputProps,
      classes: classesProp,
      style,
      className
    },
    ref
  ) => {
    const inputValue = value || "";
    const inputDisabled = disabled || loading;
    const classes = useStyles({ ...classesProp, showLabel });

    return (
      <div
        className={className}
        style={{ width: "100%", position: "relative", ...style }}
      >
        <FormControl style={{ width: "100%" }}>
          {showLabel && (
            <InputLabel className={classes.label} shrink htmlFor={id}>
              {label}
              {required && (
                <span style={{ color: "#C71612", marginLeft: "2px" }}>*</span>
              )}
            </InputLabel>
          )}
          <Input
            id={id}
            classes={{
              root: classes.rootInput,
              focused: classes.rootInputFocused,
              disabled: classes.rootInputDisabled,
              error: classes.rootInputError,
              input: classes.input
            }}
            value={loading ? "" : inputValue}
            onChange={onChange}
            inputProps={{ "aria-label": "description", ...inputProps }}
            disabled={inputDisabled}
            required={required}
            inputRef={ref}
            onBlur={onBlur}
            name={name}
            error={error}
            type={type}
            placeholder={placeholder}
            fullWidth
            disableUnderline
          />
        </FormControl>
        <div className={styles["input-loader-container-1"]}>
          <div
            className={styles["input-loader-container-2"]}
            style={showLabel ? {} : { marginTop: 0 }}
          >
            {loading && (
              <Skeleton
                width="100%"
                height={14}
                borderRadius="5px"
                containerClassName={styles["input-loader"]}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default TextInput;
