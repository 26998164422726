// menu titles for breadcrumbs

export async function getLeftMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'ti-home',
      url: '/',
      children: [
        {
          key: 'dashboard-update-trip-info',
          title: 'Update Trip info',
          url: '/dashboard/change/:booking_id/:trip_id',
        },
        {
          key: 'dashboard-ride-detail',
          title: 'View Ride Detail',
          url: '/dashboard/bookings-view/:id',
        },
        {
          key: 'dashboard-task-panel',
          title: 'Task Panel',
          url: '/dashboard/task-panel',
        },
        {
          key: 'dashboard-sevice-map',
          title: 'Service Map',
          url: '/dashboard',
        },
      ],
    },
    {
      title: 'Rider Services',
      key: 'rider',
      icon: 'ti-light-bulb',
      url: '/',
      children: [
        {
          key: 'booking_management',
          title: 'Booking',
          url: '/rider-services/bookings',
          children: [
            {
              key: 'future-booking',
              title: 'Future Bookings',
              url: '/rider-services/bookings/futurebookinglist',
            },
            {
              key: 'booking-info',
              title: 'Booking Info',
              url: '/rider-services/bookings/view/:id',
            },
            {
              key: 'new-subscription',
              title: 'New Subscription',
              url: '/rider-services/bookings/newsubscription/:id',
            },
            {
              key: 'booking-change',
              title: 'Booking Change',
              url: '/rider-services/bookings/changePaymentStatus/:id',
            },
            {
              key: 'booking-preparenew',
              title: 'New Booking',
              url: '/rider-services/bookings/prepareNew',
            },
            {
              key: 'booking-newBooking',
              title: 'New Booking',
              url: '/rider-services/bookings/newBooking',
            },
            {
              key: 'booking-change',
              title: 'Booking Change',
              url: '/rider-services/bookings/change/:id',
            },
            {
              key: 'booking-update',
              title: 'Booking Update',
              url: '/rider-services/bookings/modify/:booking_id/:passenger_id',
            },
          ],
        },
        {
          key: 'member_management',
          title: 'Member',
          url: '/rider-services/members',
          children: [
            {
              key: 'member-profile',
              title: 'Member Profile',
              url: '/rider-services/members/view/:id',
            },
            {
              key: 'member-preparenew',
              title: 'New Member',
              url: '/rider-services/members/prepareNew',
            },
            {
              key: 'member-update',
              title: 'Member Profile Update',
              url: '/rider-services/members/modify/:user_id',
            },
          ],
        },
        {
          key: 'nem-transport',
          title: 'NEMT Import',
          url: '/rider-services/nem-transport',
        },
        {
          key: 'broker-integration',
          title: 'Broker',
          url: '/rider-services/broker-integration',
        },
        {
          key: 'nem-transport-selectInsurance',
          title: 'Import',
          url: '/rider-services/nem-transport/select',
        },
        {
          key: 'nem-transport-import',
          title: 'Import',
          url: '/rider-services/nem-transport/fileUpload/:insurance',
        },
        {
          key: 'nem-transport-newInsurance',
          title: 'New Insurance',
          url: '/rider-services/nem-transport/newInsurance',
        },
        {
          key: 'new-rider',
          title: 'New ride',
          url: '/rider-services/new',
        },
        {
          key: 'rider-faqs',
          title: 'Rider FAQs',
          url: '/rider-services/faqs',
        },
        {
          key: 'riders',
          title: 'Riders',
          url: '/rider-services/riders',
        },
        {
          key: 'reservations',
          title: 'Reservations',
          url: '/rider-services/reservations',
          children: [
            {
              key: 'new-reservation',
              title: 'New Reservation',
              url: '/rider-services/reservations/new',
            },
          ],
        },
      ],
    },
    {
      title: 'Reports',
      key: 'reports',
      icon: 'ti-paint-bucket',
      url: '/',
      children: [
        {
          key: 'reports-graphs',
          title: 'Graphs',
          url: '/reports/graphs',
        },
        {
          key: 'reports-map',
          title: 'Origin-Destination Map',
          url: '/reports/map',
        },
        {
          key: 'reports-history',
          title: 'History',
          url: '/reports/history',
          children: [
            {
              key: 'reports-history-view',
              title: 'Booking View',
              url: '/reports/history/view/:id',
            },
          ],
        },
      ],
    },
    {
      title: 'Fleet',
      key: 'fleet',
      icon: 'ti-infinite',
      url: '/',
      children: [
        {
          title: 'Vehicle',
          key: 'vehicle',
          url: '/fleet/vehicles',
          children: [
            {
              key: 'vehicles-list',
              title: 'Vehicle List',
              url: '/fleet/vehicles',
            },
            {
              key: 'new-vehicle',
              title: 'New Vehicle',
              url: '/fleet/vehicles/add',
            },
            {
              key: 'view-vehicle',
              title: 'Vehicle Info',
              url: '/fleet/vehicles/view/:id',
            },
            {
              key: 'update-vehicle',
              title: 'Vehicle Update',
              url: '/fleet/vehicles/modify/:id',
            },
          ],
        },
        {
          title: 'Drivers',
          key: 'drivers',
          url: '/fleet/drivers',
          children: [
            {
              key: 'drivers-list',
              title: 'Drivers List',
              url: '/fleet/drivers',
            },
            {
              key: 'view-drivers',
              title: 'Driver Info',
              url: '/fleet/drivers/view/:id',
            },
            {
              key: 'update-drier',
              title: 'Driver Update',
              url: '/fleet/drivers/modify/:id',
            },
            {
              key: 'fleet-drivers-prepare',
              title: 'New Driver',
              url: '/fleet/drivers/prepare',
            },
            {
              key: 'new-driver',
              title: 'New Driver',
              url: '/fleet/drivers/new',
            },
            {
              key: 'shift-schedule',
              title: 'Driver Shift Schedule',
              url: '/fleet/drivers/schedule',
            },
            {
              key: 'shift-report',
              title: 'Driver Shift Report',
              url: '/fleet/drivers/report',
            },
          ],
        },
        {
          title: 'Trips',
          key: 'trips',
          url: '/fleet/trips',
          children: [
            {
              key: 'trips-list',
              title: 'Trips List',
              url: '/fleet/trips',
            },
          ],
        },
        {
          title: 'Subscription management',
          key: 'subscription',
          url: '/fleet/subscription',
          children: [
            {
              key: 'subscription-list',
              title: 'Subscription List',
              url: '/fleet/sucscription',
            },
            {
              key: 'view-subscription',
              title: 'Subscription detail',
              url: '/fleet/subscription/view/:id',
            },
          ],
        },
        {
          title: 'Dispatch Board',
          key: 'dispatch-board',
          url: '/fleet/dispatch',
          // children: [
          //   {
          //     key: "dispatch-list",
          //     title: "Trips List",
          //     url: "/fleet/trips"
          //   }
          // ]
        },
        {
          title: 'Template',
          key: 'dispatch-board',
          url: '/fleet/dispatchtemplate',
        },
        {
          title: 'Live dispatch',
          key: 'live-dispatch',
          url: '/rider-services/bookings/assess',
          children: [
            {
              key: 'first-trip',
              title: 'First Trip of Day',
              url: '/rider-services/bookings/assess/first-trip',
            },
          ],
        },
      ],
    },
    {
      title: 'Programs',
      key: 'programs',
      icon: 'ti-files',
      url: '/',
      children: [
        {
          key: 'services',
          title: 'Services',
          url: '/programs/services',
        },
        {
          key: 'accounts',
          title: 'Accounts',
          url: '/programs/accounts',
        },
        {
          key: 'feePlans',
          title: 'Fee Plans',
          url: '/programs/pricing',
        },
      ],
    },

    {
      title: 'App Training',
      key: 'app-training',
      icon: 'ti-shine',
      children: [
        {
          key: 'rider-app',
          title: 'Rider app',
          url: '/training/rider-app',
        },
        {
          key: 'driver-app',
          title: 'Driver app',
          url: '/training/driver-app',
        },
        {
          key: 'web-app',
          title: 'Web app',
          url: '/training/web-app',
        },
      ],
    },
    {
      title: 'Account Management',
      key: 'account-management',
      icon: 'ti-menu-alt',
      url: '/',
      children: [
        {
          key: 'users',
          title: 'Users',
          url: '/account_mngm/users',
        },
        {
          key: 'plan-billing',
          title: 'Billing',
          url: '/account_mngm/plan-billing',
        },
        {
          key: 'payment-method',
          title: 'Payment Method',
          url: '/account_mngm/payment-method',
          children: [
            {
              key: 'stripe-payment',
              title: 'Stripe Payment',
              url: '/account_mngm/payment-method/stripe-payment',
            },
          ],
        },
      ],
    },
    {
      title: 'Contact Duet',
      key: 'contact-duet',
      icon: 'ti-email',
      url: '/',
      children: [
        {
          key: 'contact-info',
          title: 'Contact info',
          url: '/contact/contact-info',
        },
      ],
    },
  ];
}

export default getLeftMenuData;
