import React from "react";

import styles from "./customInput.module.scss";

const CustomInput = ({
  label,
  inputType = "text",
  value,
  full = false,
  isEdit = false,
  icon,
  iconClick = () => {},
  inputCustomClass = "",
  isRequired = false,
  readOnly = true,
  inputClickable = true,
  onChangeEvent = () => {},
  onKeyUpEvent = () => {},
  IconCustomClass = "",
  disabled = false,
  inputContainerCustomClass = ""
}) => {
  return (
    <div
      className={`${styles.input} ${
        full ? styles["input-full"] : ""
      } ${inputContainerCustomClass}`}
    >
      {label && label !== "" && (
        <div className={styles.label}>
          {label} {isRequired && <span className={styles.required}>*</span>}
        </div>
      )}

      {icon && isEdit ? (
        <>
          <input
            type={inputType}
            className={`${styles["text-input"]} ${inputCustomClass}`}
            value={value}
            onClick={icon && isEdit && inputClickable ? iconClick : () => {}}
            onPaste={e => e.preventDefault()}
            onChange={onChangeEvent}
            readOnly={readOnly}
            disabled={disabled}
            onKeyUp={onKeyUpEvent}
          />
          {icon !== "" && (
            <span
              className={`${styles["input-icon"]} ${IconCustomClass}`}
              onClick={iconClick}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
            >
              <img src={icon} alt="icon" />
            </span>
          )}
        </>
      ) : (
        <input
          type={inputType}
          className={`${styles["text-input"]} ${inputCustomClass}`}
          value={value}
          readOnly={readOnly}
          onChange={onChangeEvent}
          disabled={disabled}
          onKeyUp={onKeyUpEvent}
        />
      )}
    </div>
  );
};

export default CustomInput;
