/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */

import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";

import "./driver-schedule.css";

import { adjustTimeRange, formatAMPM, setDBTime } from "../../../lib/common";
import TimeInput from "../../../components/Elements/TextInput/TimeInput";

const LunchBreakModal = ({
  setShowModal,
  save,
  setStart,
  setEnd,
  start,
  end,
  show,
  dispatchDate,
  viewedBooking
}) => {
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const saveStartTime = val => {
    try {
      setStart(setDBTime(val, dispatchDate));
    } catch (e) {
      console.error(e);
    }
    setStartTime(val);
  };

  const saveEndTime = val => {
    try {
      setEnd(setDBTime(val, dispatchDate));
    } catch (e) {
      console.error(e);
    }
    setEndTime(val);
  };

  const handleSaveStartTime = val => {
    if (val && endTime) {
      let originalBooking = null;
      if (viewedBooking) {
        const { scheduled_dropoff_time, scheduled_pickup_time } = viewedBooking;
        originalBooking = {
          start: setDBTime(scheduled_pickup_time, dispatchDate),
          end: setDBTime(scheduled_dropoff_time, dispatchDate)
        };
      }

      const newEndTime = adjustTimeRange(
        val,
        endTime,
        "start",
        dispatchDate,
        originalBooking
      );
      if (newEndTime && newEndTime !== "reset") {
        handleSaveEndTime(newEndTime); // hh:mm AM/PM
      }
    }

    saveStartTime(val);

    // eslint-disable-next-line
  };

  const handleSaveEndTime = val => {
    if (startTime && val) {
      const newStartTime = adjustTimeRange(startTime, val, "end", dispatchDate);
      if (newStartTime && newStartTime !== "reset") {
        saveStartTime(newStartTime); // hh:mm AM/PM
      } else if (newStartTime === "reset") {
        saveStartTime("12:00 AM");
        saveEndTime(`1:00 AM`); // hh:mm AM/PM

        return;
      }
    }
    saveEndTime(val);
    // eslint-disable-next-line
  };

  useEffect(() => {
    if (start) {
      setStartTime(formatAMPM(new Date(start)));
    }
    if (end) {
      setEndTime(formatAMPM(new Date(end)));
    }
    // eslint-disable-next-line
  }, [end, start]);

  useEffect(() => {
    // this will reset the values of the input
    // since user did not save changes
    if (!show && viewedBooking) {
      const { scheduled_dropoff_time, scheduled_pickup_time } = viewedBooking;

      setStartTime(scheduled_pickup_time);
      setEndTime(scheduled_dropoff_time);
    }
    // eslint-disable-next-line
  }, [show]);

  return (
    <>
      <Modal
        dialogClassName="auto-size"
        show={show}
        onHide={() => setShowModal(false)}
        centered
      >
        <ModalBody dialogClassName="auto-size">
          <div
            key={show}
            className="card-box"
            style={{ marginBottom: 0, padding: "50px 30px 50px 30px" }}
          >
            <div
              style={{ fontSize: "12px" }}
              id="edit-modal"
              className=""
              onClick={e => {
                if (e.target.id === "edit-modal") {
                  setShowModal(false);
                }
              }}
            >
              <div className="edit-modal-content timeblock">
                <div className="edit-modal-shift timeblock-title">
                  Add or edit lunch break
                </div>
                <br />
                <div className="timeblock-title notes">
                  <b>Note:</b> this is visible to driver.
                </div>
                <div className="">
                  <div className="portal-time-block">
                    <TimeInput
                      label="Start time"
                      subLabel="(enter a value or use the drop-down list)"
                      value={startTime}
                      icon="/resources/images/Time_Square.png"
                      isEdit
                      placeHolder=""
                      readOnly={false}
                      onChangeEvent={value => handleSaveStartTime(value)}
                    />
                  </div>
                  <div className="portal-time-block">
                    <TimeInput
                      label="End time"
                      subLabel="(enter a value or use the drop-down list)"
                      value={endTime}
                      icon="/resources/images/Time_Square.png"
                      isEdit
                      placeHolder=""
                      readOnly={false}
                      onChangeEvent={value => handleSaveEndTime(value)}
                    />
                  </div>
                </div>
                <div className="update-buttons">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ float: "left" }}
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={save}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LunchBreakModal;
