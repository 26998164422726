// menu titles for left menu

export async function getTopData() {
  return [
    {
      title: 'Main',
      key: 'main',
      groupHeader: true,
      is_admin: [0, 1, 2, 3, 4],
    },
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'ti-home',
      is_admin: [1, 3],
      children: [
        {
          key: 'service-map',
          title: 'Service Map',
          url: '/dashboard',
        },
        {
          key: 'task-panel',
          title: 'Task Panel',
          url: '/dashboard/task-panel',
        },
      ],
    },
    {
      title: 'Rider Services',
      key: 'rider',
      icon: 'ti-mobile',
      is_admin: [1, 2],
      children: [
        {
          key: 'reservations',
          title: 'Reservations',
          url: '/rider-services/reservations',
        },
        {
          key: 'booking',
          title: 'Bookings',
          url: '/rider-services/bookings',
        },
        {
          key: 'nem-transport',
          title: 'NEMT Import',
          url: '/rider-services/nem-transport',
        },
        {
          key: 'broker-integration',
          title: 'Broker Integration',
          url: '/rider-services/broker-integration',
        },
        {
          key: 'riders',
          title: 'Riders',
          url: '/rider-services/riders',
        },
        {
          key: 'members',
          title: 'Members',
          url: '/rider-services/members',
        },
        {
          key: 'rider-faqs',
          title: 'Rider FAQs',
          url: 'http://www.duetinc.com/faqs.html',
          toLinkOutsource: true,
        },
      ],
    },
    {
      title: 'Reports',
      key: 'reports',
      icon: 'ti-bar-chart',
      is_admin: [3, 4],
      children: [
        {
          key: 'reports-graphs',
          title: 'Graphs',
          url: '/reports/graphs',
        },
        {
          key: 'reports-map',
          title: 'O-D Map',
          url: '/reports/map',
        },
        {
          key: 'reports-history',
          title: 'History',
          url: '/reports/history',
        },
      ],
    },
    {
      title: 'Fleet',
      key: 'fleet',
      icon: 'ti-infinite',
      is_admin: [3, 4],
      children: [
        {
          key: 'fleet-dispatch-board',
          title: 'Dispatch Board',
          url: '/fleet/dispatch',
        },
        {
          key: 'fleet-template',
          title: 'Template',
          url: '/fleet/dispatchtemplate',
        },
        {
          key: 'live-dispatch',
          title: 'Live Dispatch',
          url: '/rider-services/bookings/assess',
        },
        {
          key: 'fleet-subscription',
          title: 'Subscription',
          url: '/fleet/subscription',
        },
        {
          key: 'shift-schedule',
          title: 'Shift Schedule',
          url: '/fleet/drivers/schedule',
        },
        {
          key: 'shift-report',
          title: 'Shift Report',
          url: '/fleet/drivers/report',
        },
        {
          key: 'fleet-drivers',
          title: 'Drivers',
          url: '/fleet/drivers',
        },
        {
          key: 'vehicles',
          title: 'Vehicles',
          url: '/fleet/vehicles',
        },
        {
          key: 'fleet-trips',
          title: 'Trips',
          url: '/fleet/trips',
        },
      ],
    },
    {
      title: 'Programs',
      key: 'programs',
      icon: 'ti-files',
      is_admin: [3, 4],
      children: [
        {
          key: 'services',
          title: 'Services',
          url: '/programs/services',
        },
        {
          key: 'accounts',
          title: 'Accounts',
          url: '/programs/accounts',
        },
        {
          key: 'feePlans',
          title: 'Fee Plans',
          url: '/programs/pricing',
        },
      ],
    },
    {
      title: 'App Training',
      key: 'app-training',
      icon: 'ti-light-bulb',
      is_admin: [0, 1, 2, 3, 4],
      children: [
        {
          key: 'rider-app',
          title: 'Rider App',
          url: 'http://www.duetinc.com/riderapp.html',
          toLinkOutsource: true,
        },
        {
          key: 'driver-app',
          title: 'Driver App',
          url: 'http://www.duetinc.com/driverapp.html',
          toLinkOutsource: true,
        },
        {
          key: 'web-app',
          title: 'Web App',
          url: 'http://www.duetinc.com/webapp.html',
          toLinkOutsource: true,
        },
      ],
    },
    {
      title: 'Account Management',
      key: 'account-management',
      icon: 'ti-settings',
      is_admin: [3, 4],
      children: [
        {
          key: 'users',
          title: 'Users',
          url: '/account_mngm/users',
        },
        {
          key: 'plan-billing',
          title: 'Billing',
          url: '/account_mngm/plan-billing',
        },
        {
          key: 'payment-method',
          title: 'Payment Method',
          url: '/account_mngm/payment-method',
        },
      ],
    },
    {
      title: 'Contact Duet',
      key: 'contact-duet',
      icon: 'ti-email',
      is_admin: [0, 1, 2, 3, 4],
      children: [
        {
          key: 'contact-info',
          title: 'Contact info',
          url: '/contact/contact-info',
        },
      ],
    },
  ];
}

export default getTopData;
