import React, { useState } from "react";
import dayjs from "dayjs";

import IconButton from "@material-ui/core/IconButton";
import "./booking-details.css";
import ModifyAccountModal from "../../programs/accounts/ModifyAccountModal";

const BookingDetails = ({
  booking,
  selectedDate,
  close,
  carName,
  removeBooking,
  rideStatus,
  getAvailableBookingTime,
  showRoutes,
  showTrackingLink
}) => {
  const [showAccountView, setShowAccountView] = useState(false);
  const [viewAccount, setViewAccount] = useState(null);
  const handleOverLayClick = e => {
    if (e.target.className === "booking__details--overlay") {
      close();
    }
  };

  return (
    <div
      className="booking__details--overlay"
      onClick={handleOverLayClick}
      onKeyPress={handleOverLayClick}
      role="button"
      tabIndex="0"
    >
      <div
        className={`booking__details--modals ${
          showAccountView ? "booking__account_view" : ""
        } `}
      >
        {showAccountView ? (
          <ModifyAccountModal
            accountId={viewAccount}
            close={() => {
              setShowAccountView(false);
              setViewAccount(null);
            }}
          />
        ) : (
          <>
            <div className="booking__details_view">
              <div className="booking_details_container">
                <div className="booking__details--header">
                  {booking.type !== "autoScheduling" && (
                    <>
                      <span
                        onClick={() => removeBooking(booking.booking_id)}
                        onKeyPress={() => removeBooking(booking.booking_id)}
                        role="button"
                        tabIndex="0"
                      >
                        <IconButton>
                          <i className="fa fa-trash-o" />
                        </IconButton>
                      </span>
                      <span
                        onClick={() => showRoutes(true)}
                        onKeyPress={() => showRoutes(true)}
                        role="button"
                        tabIndex="0"
                      >
                        <IconButton>
                          <i className="fa fa-map" />
                        </IconButton>
                      </span>
                      <span
                        key={booking.trip_id}
                        onClick={() => showTrackingLink(booking.trip_id)}
                        onKeyPress={() => showTrackingLink(booking.trip_id)}
                        role="button"
                        tabIndex="0"
                      >
                        <IconButton>
                          <i className="fa fa-link" />
                        </IconButton>
                      </span>
                      <span
                        onClick={() => getAvailableBookingTime(booking)}
                        onKeyPress={() => getAvailableBookingTime(booking)}
                        role="button"
                        tabIndex="0"
                      >
                        <IconButton>
                          <i className="fa fa-edit" />
                        </IconButton>
                      </span>
                    </>
                  )}
                  <span
                    onClick={close}
                    onKeyPress={close}
                    role="button"
                    tabIndex="0"
                  >
                    <IconButton>
                      <svg
                        className="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      </svg>
                    </IconButton>
                  </span>
                </div>

                <div className="booking__details--content">
                  <div className="booking__details__name">
                    {`${booking.passenger_firstname} ${
                      booking.passenger_lastname
                    }`}
                  </div>

                  <div className="booking__details__date">
                    {dayjs(selectedDate).format("dddd, LL")}
                  </div>

                  <>
                    <div className="booking__details__status">
                      <strong>Status:</strong> {rideStatus(booking)}
                    </div>

                    <div className="booking__details__schedule">
                      <div>
                        <strong>On Route:</strong>{" "}
                        {booking.driver_enroute_time || "n/a"} actual
                      </div>
                      <div>
                        <strong>Travel distance and time:</strong>&nbsp;
                        {booking.travel_distance
                          ? `${(booking.travel_distance * 0.000621371).toFixed(
                              2
                            )} miles, `
                          : ""}
                        {booking.travel_time
                          ? `${Math.ceil(booking.travel_time / 60)} minutes`
                          : ""}{" "}
                      </div>
                      <div>
                        <strong>Arrived:</strong>{" "}
                        {booking.driver_arrival_time
                          ? `${booking.driver_arrival_time} at Pickup`
                          : ""}{" "}
                        {booking.driver_arrival_dropoff_time
                          ? `, ${
                              booking.driver_arrival_dropoff_time
                            } at Drop-off`
                          : ""}
                        {!booking.driver_arrival_time &&
                          !booking.driver_arrival_dropoff_time &&
                          "n/a"}
                      </div>
                      <div>
                        <strong>Pickup:</strong> {booking.scheduled_pickup_time}{" "}
                        scheduled, {booking.actual_pickup_time || "n/a"} actual
                      </div>
                      <div>
                        <strong>Drop-off:</strong>{" "}
                        {booking.scheduled_dropoff_time} scheduled,{" "}
                        {booking.actual_dropoff_time || "n/a"} actual
                      </div>
                    </div>

                    <div className="booking__details__car-name">
                      <strong>Vehicle: </strong>
                      {carName}
                    </div>

                    <div className="booking__details__assistance">
                      <strong>Mobility Aid: </strong>
                      {booking.mobility_assistance.map(
                        assistance => `${assistance} `
                      )}
                    </div>

                    <div className="booking__details__pickup">
                      <strong>Pickup: </strong>
                      {booking.pickup_account_id &&
                      booking.pickup_account_id !== "" ? (
                        <span
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                          className="account__link"
                          onClick={() => {
                            setShowAccountView(true);
                            setViewAccount(booking.pickup_account_id);
                          }}
                        >
                          {booking.pickup_address}
                        </span>
                      ) : (
                        booking.pickup_address
                      )}
                    </div>

                    <div className="booking__details__drop-off">
                      <strong>Drop-off: </strong>
                      {booking.dropoff_account_id &&
                      booking.dropoff_account_id !== "" ? (
                        <span
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                          className="account__link"
                          onClick={() => {
                            setShowAccountView(true);
                            setViewAccount(booking.dropoff_account_id);
                          }}
                        >
                          {booking.dropoff_address}
                        </span>
                      ) : (
                        booking.dropoff_address
                      )}
                    </div>

                    <div className="booking__details__fee">
                      <strong>Payment: </strong>
                      {booking.insurance_account_id &&
                      booking.insurance_account_id !== "" ? (
                        <span
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                          className="account__link"
                          onClick={() => {
                            setShowAccountView(true);
                            setViewAccount(booking.insurance_account_id);
                          }}
                        >
                          {booking.payment}
                        </span>
                      ) : (
                        booking.payment
                      )}
                      , $
                      {(
                        (booking.ride_fee + booking.total_addl_fee_usd_cents) /
                        100
                      ).toFixed(2)}
                    </div>

                    <div className="booking__details__fee">
                      <strong>Admin to Admin Note:</strong>{" "}
                      {booking.office_note}
                    </div>

                    <div className="booking__details__fee">
                      <strong>Admin to Driver Note:</strong>{" "}
                      {booking.admin_note}
                    </div>

                    <div className="booking__details__fee">
                      <strong>Driver to Admin Note:</strong>{" "}
                      {booking.driver_note ? booking.driver_note : ""}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BookingDetails;
