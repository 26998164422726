/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { BackTop, Layout } from 'antd';
import classNames from 'classnames';
import { useCallback } from 'react';

import Settings from 'components/LayoutComponents/Settings';
import TopBar from 'components/LayoutComponents/TopBar';
import SideMenu from 'components/LayoutComponents/Menu';
import Breadcrumbs from 'components/LayoutComponents/Breadcrumbs';
import Footer from 'components/LayoutComponents/Footer';

import Loader from '../../components/LayoutComponents/Loader';
import styles from './style.module.scss';
import AppVersionChecker from './AppVersionChecker';
import { ToggleSwipeProvider } from '../../hooks/useIsMobile';

@connect(
  ({ settings, common }) => ({
    isMenuCollapsed: settings.isMenuCollapsed,
    common,
  }),
  null
)
class MainLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: false,
    };
  }

  componentDidMount() {
    const value =
      localStorage.getItem('newAppVersion') && localStorage.getItem('newAppVersion') === 'true' ? true : false;
    this.setState({
      showNotification: value,
    });
  }

  render() {
    const { children, isMenuCollapsed, common } = this.props;
    const { showNotification } = this.state;

    return (
      <ToggleSwipeProvider>
        <Layout
          id="wrapper"
          className={classNames('wrapper', {
            enlarged: isMenuCollapsed,
          })}
        >
          <Loader spinning={common.loading} />

          <BackTop />
          <TopBar />
          <Settings />

          <Layout hasSider className={`${showNotification ? styles.height__flex__direction : ''}`}>
            {/* Temporary disable version checker, still in progress */}
            <AppVersionChecker
              key={showNotification}
              showNotification={showNotification}
              setShowNotification={(value) => this.setState({ showNotification: value })}
            />

            <SideMenu addPadding={showNotification} />
            <Layout className={`content-page ${showNotification ? styles.adjusted__height : ''}`}>
              <Layout.Content
                className={`content ${styles['layout-content']}`}
                style={{ marginTop: showNotification ? 0 : '70px' }}
              >
                <div className="container-fluid">
                  <Breadcrumbs />
                  <div className={styles.utils__content}>{children}</div>
                </div>
              </Layout.Content>
              <Layout.Footer>
                <Footer />
              </Layout.Footer>
            </Layout>
          </Layout>
        </Layout>
      </ToggleSwipeProvider>
    );
  }
}

export default MainLayout;
